<template>
  <modal name="add-user-modal" @before-open="beforeOpen" @closed="clear" :adaptive="true" height="auto" :maxWidth="438"
         class="modal-add-user modal">
    <h3 class="modal-title">Add users</h3>
    <p class="modal-text">Here you can add users to subamin</p>
    <Dropdown
      label="Add Users"
      placeholder="Choose on list"
      :list="mapUsers"
      :value="mapSelected"
      @selected="select"
    />
    <ul class="modal-add-user-list df" v-if="selected.length > 0">
      <li class="modal-add-user-item" @click="remove(item)" v-for="(item, idx) in filterSelected" :key="`user-${idx}`">
        <span>{{ item.email }}</span>
        <Remove/>
      </li>
    </ul>
    <div class="modal-delete-buttons df">
      <button class="btn blue" @click="save">Save</button>
      <button class="btn red" @click="close">Cancel</button>
    </div>
    <Preloader v-if="pending" />
  </modal>
</template>

<script>
import modal from "@/mixins/modal";
import Remove from '@/assets/icons/remove.svg'
import {mapActions, mapGetters} from "vuex";
import Preloader from "../common/Preloader";

export default {
  name: "UserAddModal",
  mixins: [modal],
  components: {
    Preloader,
    Dropdown: () => import('@/components/common/ADropdown'),
    Remove
  },
  data() {
    return {
      list: [],
      selected: [],
      pending: false
    }
  },
  computed: {
    ...mapGetters(['selectedUser']),
    mapUsers(){
      return this.list.map(u => ({
        name: u.email,
        value: u.id
      }))
    },
    mapSelected(){
      return this.selected.filter(u => !u.toRemove).map(u => ({
        name: u.email,
        value: u.id
      }))
    },
    filterSelected(){
      return this.selected.filter(u => !u.toRemove)
    }
  },
  methods: {
    ...mapActions(['setTempUser', 'getSubUsers', 'setSubUserVisibility', 'getAllUsers']),
    close() {
      this.$modal.hide('add-user-modal');
    },
    clear(){
      this.selected = [];
      this.list = [];
      this.pending = false;
      this.setTempUser(null);
    },
    async save() {
      this.pending = true;
      const toAdd = this.selected.filter(u => u.toAdd).map(u => u.id),
        toRemove = this.selected.filter(u => u.toRemove).map(u => u.id);
      const promises = [];

      if(toAdd.length > 0){
        promises.push(this.setSubUserVisibility({
          users_ids: toAdd,
          status: 'visible',
          subadmin_id: this.selectedUser.id
        }))
      }
      if(toRemove.length > 0){
        promises.push(this.setSubUserVisibility({
          users_ids: toRemove,
          status: 'invisible',
          subadmin_id: this.selectedUser.id
        }))
      }
      if(toAdd.length > 0 || toRemove.length > 0){
        await Promise.all(promises).then(() => {
          this.close();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Get users list',
            text: error.response.data.message,
            type: 'error'
          });
        });
      } else {
        this.close();
      }
    },
    select(item) {
      const fIdx = this.selected.findIndex(x => x.id === item.value)
      if (fIdx === -1) {
        this.selected.push({...this.list.find(l => l.id === item.value), toAdd: true})
      } else if(this.selected[fIdx].toRemove) {
        const tempSelected = [...this.selected];
        delete tempSelected[fIdx].toRemove;
        this.selected = tempSelected;
      }
    },
    remove(item) {
      const itemIdx = this.selected.findIndex(x => x.id === item.id);
      if(this.selected[itemIdx].toAdd){
        this.selected.splice(itemIdx, 1);
      } else {
        const tempSelected = [...this.selected];
        tempSelected[itemIdx].toRemove = true;
        this.selected = tempSelected;
      }
    },
    async beforeOpen() {
      this.pending = true;
      const promises = [
        this.getSubUsers(this.selectedUser.id),
        this.getAllUsers(['USER'])
      ];

      await Promise.all(promises).then((resp) => {
        this.selected = resp[0];
        this.list = resp[1];
        this.pending = false;
      }).catch(error => {
        this.pending = false;
        console.error('Error get users: ', error);
        this.$notify({
          title: 'Get users list',
          text: error.response.data.message,
          type: 'error'
        });
      })
    }
  }
}
</script>

<style scoped>

</style>
